<template>
  <div class="gray">
    <div id="wemap-header"></div>
    <router-view />
    <div id="wemap-footer"></div>
  </div>
</template>
<script>
export default {
  name: 'DefaultLayout',
  mounted() {
    window.TWeMapHeader.init({
      container: document.getElementById('wemap-header'),
      theme: 'dark-transparent',
    });
    window.TWeMapFooter.init({
      container: document.getElementById('wemap-footer'),
    });
    console.log('home layout init');
  },
  methods: {
  },
};
</script>
<style lang="less">
@import '@/styles/index.less';
.wemap-page-header, .wemap-page-footer {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>
